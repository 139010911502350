import { useEffect, useState } from "react";
import {  forbiddenFunction, getfList } from "../ConfigFiles/SharedFunctions";
import { urls } from "../ConfigFiles/Url";
import CryptoJS from "crypto-js"

const SchedyoPrivateRoute = ({ children, link }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    let res = await getfList(urls.authenticate.getAccessLinks)
    console.log(res,"res")
    let links = res?.map((e) => {
      return e.linkName
    })
    let encryptedtext = CryptoJS.AES.encrypt(JSON.stringify(links), "Schedyo123").toString();
    sessionStorage.setItem("accessLinks", encryptedtext)
    if (links.includes(link)) {
      setAuthenticated(true)
    }
    setLoading(false)
    return () => {
      setAuthenticated(false)
    }
  }, [])
  if (loading) {
    return <h1>Loading...</h1>
  }

  return authenticated ? children : forbiddenFunction()
  // return CheckLink(link) ? children : forbiddenFunction()
  // return children
}

export default SchedyoPrivateRoute;










// import { CheckLink,  forbiddenFunction } from "../ConfigFiles/SharedFunctions";

// const SchedyoPrivateRoute = ({ children ,link }) => {
//   return CheckLink(link) ? children : forbiddenFunction()
//   // return children
// }

// export default SchedyoPrivateRoute;

