import React from 'react'
import { useNavigate } from 'react-router-dom'

const Forbidden = () => {
    const navigate = useNavigate()
    return (
        <div style={{ height: "100vh", backgroundColor: "#28876B" }} class=" w-100   d-flex flex-column align-items-center justify-content-center border">
            <h4 style={{ color: "#fa7507" }}>Access Denied</h4>
            {/* <h4 style={{ color: "#fa7507" }}>You are accessing UnAuthorized pages.</h4> */}
            <h4 style={{ color: "#fa7507" }}>Please go to <a style={{ color: "#fa7507",borderBottom:"solid 3px #fa7507" }} href="/login">home.</a></h4>
            {/* <p>access not granted</p>
            <p>Please contact Admin</p>
            <button className='p-2' style={{ backgroundColor: "#74a3ed" }} onClick={() => { navigate('/login') }}>Go to Login</button> */}
        </div>
    )
}

export default Forbidden