import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { getfList } from "./ConfigFiles/SharedFunctions";
import { urls } from "./ConfigFiles/Url";
import Forbidden from "./Forbidden/Forbidden";

import SchedyoPatientPrivateRoute from "./PrivateRoutes/SchedyoPatientPrivateRoute";
import SchedyoPrivateRoute from "./PrivateRoutes/SchedyoPrivateRoute";
import CryptoJS from "crypto-js"
import { useSelector } from "react-redux";

const LoginComponent = lazy(() => import("./Schedyo/Login"));
const CreatePasswordComponent = lazy(() => import("./Schedyo/CreatePassword"));
const CredCreatePasswordComponent = lazy(() => import("./Credentialing/CreatePassword"));
const PatientPortalComponent = lazy(() => import("./Schedyo/PatientPortal/Patient"));
const SchedyoRoutingConfigComponent = lazy(() => import("./Schedyo/RoutingConfig"));
const EHRRoutingConfigComponent = lazy(() => import("./EHR/RoutngConfig"));
const RCMRoutingConfigComponent = lazy(() => import("./RCM/RCMRoutingConfig"));
const CredentialingRoutingConfigComponent = lazy(() => import("./Credentialing/CredRoutingConfig"));
const HomeComponent = lazy(() => import("./Schedyo/LandingPages/Home"))
const AppointmentComponent = lazy(() => import("./Schedyo/LandingPages/appointmentRequest"))
const AppointmentViewComponent = lazy(() => import("./Schedyo/LandingPages/AppointmentView"))
const DoctorListComponent = lazy(() => import("./Schedyo/LandingPages/DoctorList"))
const CredDashboardRotingConfig = lazy(() => import("./Credentialing/Dashboard/DashboardRouting"))


function App() {
  const accessLinksStatus = useSelector((state) => state.accessLinks)

  const accesslinks = async () => {
    let res = await getfList(urls.authenticate.getAccessLinks)
    console.log(res, "res")
    let links = res?.map((e) => {
      return e.linkName
    })
    let encryptedtext = CryptoJS.AES.encrypt(JSON.stringify(links), "Schedyo123").toString();
    sessionStorage.setItem("accessLinks", encryptedtext)
  }

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE
  }, [process.env.REACT_APP_TITLE]);

  useEffect(()=>{
    accesslinks()
    },[accessLinksStatus])

  return (
    <>
      {/* <ReactTooltip place="bottom" type="success" effect="solid" /> */}
      <Suspense fallback={() => <div>Loading ....</div>}>
        <Router>
          <Routes >
            <Route path="" element={<HomeComponent />} />
            <Route path="forbidden" element={<Forbidden />} />
            <Route path="/doctorList/:facilityId/:specialityId" element={<DoctorListComponent />} />
            <Route path="/appointmentView" element={<AppointmentViewComponent />} />
            <Route path="/appointment" element={<AppointmentComponent />} />
            <Route path="login" element={<LoginComponent />} />
            <Route path="createpassword/:token" element={<CreatePasswordComponent />} />
            <Route path="setpassword/:token" element={<CredCreatePasswordComponent />} />
            <Route path="forgotpassword/:token" element={<CreatePasswordComponent />} />
            <Route path="scheduler/*" element={<SchedyoRoutingConfigComponent />} />
            <Route path="ehr/*" element={<SchedyoPrivateRoute link={"EHR"}> <EHRRoutingConfigComponent /> </SchedyoPrivateRoute>} />
            <Route path="cred/*" element={<CredentialingRoutingConfigComponent />} />
            <Route path="credentialing/*" element={<SchedyoPrivateRoute link={"Credentialing"}> <CredDashboardRotingConfig /> </SchedyoPrivateRoute>} />
            {/* <Route path="credentialing/*" element={ <SchedyoPrivateRoute link={"Credentialing"}> <CredentialingRoutingConfigComponent /> </SchedyoPrivateRoute>} /> */}
            <Route path="rcm/*" element={<SchedyoPrivateRoute link={"RCM"}> <RCMRoutingConfigComponent /> </SchedyoPrivateRoute>} />
            <Route path="patient/*" element={<SchedyoPatientPrivateRoute ><PatientPortalComponent /> </SchedyoPatientPrivateRoute>} />
            <Route path="*" element={<LoginComponent />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
