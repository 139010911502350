import instance from "../services";
import "../App.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js"
import { BsGenderMale, BsGenderFemale, BsGenderTrans, } from "react-icons/bs"
import { CgGenderMale, CgGenderFemale } from "react-icons/cg"

export const notify = (status, msg) => {
    toast.configure();
    if (status == true) {
        toast.success(msg)
        return (<ToastContainer />)
    } else {
        toast.error(msg)
        return (<ToastContainer />)
    }
}

export const getfList = async (url) => {
    try {
        let res = await instance.get(url)
        if (res?.data?.status == true && res?.data?.data?.length != 0) {
            return res.data.data
        } else {
            return []
        }
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getList = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        console.log("here1")
        if (res?.data?.status == true && res?.data?.data?.length != 0) {
            console.log("here2")
            return res.data.data
        } else {
            console.log("here")
            return []
        }
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getFile = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true) {
            return res.data.data
        }
    } catch (e) {
        console.log(e)
        return e
    }
}


export const getStructuredRoomList = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        let room = res?.data?.data
        let rooms = [];
        room.map((e) => {
            let obj = {};
            obj['name'] = e.name;
            obj['id'] = e.id;
            rooms.push(obj)
        })
        return rooms
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getAppointments = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true) {
            let resdata = res?.data?.data;
            resdata?.map((element) => {
                element["start"] = element.startTime
                element["end"] = element.endTime
                element["text"] = "Dr. " + element.physicianName + " " + element.patientName
                element["backColor"] = element.colorCode
            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        console.log(e)
        return []
    }
}


export const getSchedule = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true) {
            let resdata = res?.data?.data;
            resdata?.map((element) => {
                element["id"] = element.id
                element["end"] = element.endActivity
                element["start"] = element.startActivity
                element["resource"] = element.physicianId
                if (element.activity == "Available") {
                    element["barColor"] = "#0F497F"
                } else {
                    element["barColor"] = "#088772"
                }
            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        console.log(e)
        return []
    }
}

export const getbyId = async (url, key, id) => {
    try {
        let res = await instance.post(url, { [key]: id })
        if (res?.data?.status == true && res?.data?.data?.length != 0) {
            return res.data.data
        } else {
            return {}
        }
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const getbyId2 = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true && res?.data?.data?.length != 0) {
            return res.data.data
        } else {
            notify(res?.data?.status, res?.data?.message)
            return {}
        }
    } catch (e) {
        console.log(e)
        return {}
    }
}


export const add = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        notify(res?.data?.status, res?.data?.message)
        return res;
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const ChangingStatus = async (url, q) => {
    try {
        let key = Object.keys(q)[0];
        const res = await instance.post(url, {
            status: q.status == "Active" ? "InActive" : "Active",
            [key]: q[key]
        });
        notify(res.data.status, res.data.message)
        return res
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const statusChange = async (url, data) => {
    try {
        const res = await instance.post(url, data);
        notify(res.data.status, res.data.message)
        return res
    } catch (e) {
        console.log(e)
        return {}
    }
}


export const getDetailedAppoitment = async (url, data) => {
    try {
        const res = await instance.post(url, data)
        if (res?.data?.status == true) {
            return res.data.data
        } else {
            return {}
        }
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const getAppointmentbyId = async (url, data) => {
    try {
        const res = await instance.post(url, data)
        if (res?.data?.status == true) {
            let resdata = res?.data?.data
            resdata['start'] = resdata.startTime
            resdata['end'] = resdata.endTime
            resdata['text'] = resdata.physicianName + " " + resdata.patientName
            resdata['backColor'] = resdata.colorCode
            return [resdata]
        } else {
            return []
        }

    } catch (e) {
        console.log(e)
        return []
    }
}


export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});




export const getstructuredScheduleBlock = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true) {
            let resdata = res?.data?.data;
            resdata?.map((element) => {
                element["start"] = element.startAt
                element["end"] = element.endAt
                element['resource'] = Number(element.room)
                element["text"] = "Block-->   " + element.description
                element['sTime'] = moment(element.startAt).format("hh:mm a")
                element['eTime'] = moment(element.endAt).format("hh:mm a")
                element['startTime'] = element.starAt
                element['endTime'] = element.endAt
            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        console.log(e)
        return []
    }
}

export const phoneFormat = (phoneNumber) => {
    return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
}

export const sliceFunction = (value) => {
    return value?.length > 30 ? value?.slice(0, value?.slice(0, 30).lastIndexOf(' ')) + "..." : value
}


export const getQuesquestionnaireData = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        if (res && res?.data?.status == true) {
            let keys = ["procedureAssissment", "allergieMedicationsSurgeries", "socialAssessments", "medicalConditions", "dischargeScreening"]
            let data = {}
            if (res.data.data.patientQuestionairs && res.data.data.patientQuestionairs != null) {
                keys.map((e) => {
                    if (res.data.data.patientQuestionairs[e] && res.data.data.patientQuestionairs[e] != null) {
                        Object.assign(data, JSON.parse(res.data.data.patientQuestionairs[e]));
                    }
                })
            } else {
                data['noQuestionaire'] = true
            }
            if (res.data.data.patientDetails && res.data.data.patientDetails != null) {
                Object.assign(data, res.data.data.patientDetails);
            }
            return data
        } else {
            return {}
        }
    } catch (e) {
        console.log(e)
        return {}
    }
}


export const getFormData = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        return res?.data;
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const settingFlag = (array, data) => {
    // sample array
    // [{ labels: ["telNum", "cellNum", "pagerNum", "faxNum"], len: 10 }, { labels: ["zipCode "], len: 5 }]
    let flag = true
    for (let j = 0; j < array.length; j++) {
        for (let i = 0; i < array[j].labels?.length; i++) {
            if (data?.[array[j].labels[i]] && data?.[array[j]?.labels[i]] != undefined && data?.[array[j]?.labels[i]] != '') {
                flag = (data?.[array[j].labels[i]].length == array[j].len)
                if (flag == false) {
                    return flag
                }
            }
        }
    }
    return flag
}

export const emptyingFields = (data, array) => {
    // sample array
    // [{key:"key",value:"Yes/No",emptyKeys:["key1",key2]},{key:"key",value:"Yes/No",["key1",key2]}]
    array.map((e) => {
        if (data[e.key] == e.value) {
            e.emptyKeys?.map((v) => {
                data[v] = ''
            })
        }
    })
    return data
}

export const emailFields = (array, data) => {
    console.log(array, data)
    let flag = true
    for (let i = 0; i < array.length; i++) {
        let dataValue = data[array[i]]
        console.log(dataValue, "dataValue")
        if (dataValue && dataValue != '' && dataValue != undefined) {
            flag = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(dataValue))
            console.log(flag, dataValue, "1")
        }
        if (flag == false) {
            console.log(flag, dataValue, "2")
            return false
        }
    }
    return flag
}

export const requiredFields = (array, data) => {
    let flag = true
    for (let i = 0; i < array.length; i++) {
        let value = data[array[i]]
        flag = value && value != undefined && value != ''
        if (flag == false || flag == undefined || flag == null) {
            return false
        }
    }
    return flag
}

export const returnValue = (value) => {
    return (value && value != "" && value != undefined) ? value : "";
};

export const phoneNumberValue = (value) => {
    return (value && value != '') ? phoneFormat(value) : ""
}

export const checkedFn = (data, value) => {
    return data && data == value ? true : false
}

export const reconvertingPhonenumber = (data, array) => {
    array.map((e, i) => {
        if (data[e] && data[e] != '' && data[e].length != 0) {
            data[e] = data[e].replace(/[^0-9]/g, "")
        }
    })
}

export const jwtDecodedData = () => {
    if (sessionStorage.getItem("token") && sessionStorage.getItem("token") != "") {
        let res = jwt_decode(sessionStorage.getItem("token"))
        return res
    }
    return {}
}

export const logOutFunction = () => {
    sessionStorage.clear()
    window.location.pathname = ("/")
}

export const forbiddenFunction = () => {
    sessionStorage.clear()
    window.location.pathname = ("/forbidden")
}

export const decryPtiedLinks = () => {
    let links = sessionStorage.getItem("accessLinks")
    if (links && links != undefined && links != '') {
        var bytes = CryptoJS.AES.decrypt(links, "Schedyo123");
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else {
        return []
    }
}

export const CheckLink = (linkName) => {
    return decryPtiedLinks()?.includes(linkName)
}

export const CheckLinks = (linkNames) => {
    for (let i = 0; i < linkNames.length; i++) {
        if (decryPtiedLinks()?.includes(linkNames[i])) {
            return true
        } else if (i + 1 == linkNames.length) {
            return false
        }
    }
    // return decryPtiedLinks()?.includes(linkName)
}

export const returnGender = (value) => {
    switch (value) {
        case "Male": return <CgGenderMale title={value} size={"1.5rem"} color="#0918e3" />
        case "Female": return <CgGenderFemale title={value} size={"1.5rem"} color="#f707e3" />
        case "Other": return <BsGenderTrans title={value} size={"1.1rem"} color="#f7e705" />
        default: return <></>
    }
}

export const setMultipleData = (data, notEmpty, keys, form) => {
    let multipleData = [];
    if (data?.[notEmpty] && data?.[notEmpty] != "") {
        let temp = {};
        keys.map((e) => {
            temp[e] = data?.[e];
        });
        multipleData.push(temp);
    }
    if (form && form.length > 0) {
        for (let i = 0; i < form.length; i++) {
            if (
                data[`${notEmpty}${form[i]?.id}`] &&
                data[`${notEmpty}${form[i]?.id}`] != ""
            ) {
                let temp = {};
                keys.map((e) => {
                    temp[e] = data?.[`${e}${form[i]?.id}`];
                });
                multipleData.push(temp);
            }
        }
    }
    return multipleData;
};

export const receiveMultipleData = (data, res, keys, form) => {
    let iresdata = {};
    if (res && res?.length > 0) {
        keys.map((e) => {
            iresdata[e] = res[0]?.[e];
        });
    }
    if (res?.length > 1) {
        for (let i = 1; i < res?.length; i++) {
            keys?.map((e) => {
                iresdata[`${e}${form[i - 1]?.id}`] = res[i]?.[e];
            });
        }
    }
    Object.assign(data, iresdata);
    return data
};

export const removingData = (data, removingArray) => {
    let keys = Object.keys(data)
    let resultArray = []
    keys.map((e, i) => {
        removingArray.map((v) => {
            if (e.includes(v)) {
                resultArray.push(e)
            }
        })
    })
    resultArray.map((e) => {
        delete data[e];
    })
    return data
}

export const returnStatusBarStyle = (status) => {
    switch (status) {
        case "Check-In":
        case "Requested": return { backgroundColor: "#6C757D", border: "solid 2px #3b3a3d" }
        case "In-Surgery":
        case "Pending": return { backgroundColor: "#faf19d", border: "solid 2px #f7eb02" }
        case "Discharge":
        case "Performed": return { backgroundColor: "#d4fac8", border: "solid 2px #3ff705" }
        case "Recovery": return { backgroundColor: "#84b3f5", border: "solid 2px #0268f7" }
        case "Canceled": return { backgroundColor: "#f7c6c6", border: "solid 2px #f50505" }
    }
}

export const removingEmptyFields = (data) => {
    let keys = Object.keys(data)
    let result = {}
    keys.map((e) => {
        if (data[e] != "") {
            result[`${e}`] = data[`${e}`]
        }
    })
    return result
    // const dataasArray = Object.entries(data);
    // return dataasArray.filter(([key, value]) => value != '');
}

export const storedFacilityId = () => {
    let facilityId = sessionStorage.getItem("facilityId")
    if (facilityId && facilityId != '' && facilityId != undefined) {
        return facilityId
    } else {
        return "0"
    }
}